// utils/useChangeLanguage.ts
import { useTranslation } from 'react-i18next';
import mapI18N from '../assets/mapI18N';

interface Locale {
  file: any;
  keys: string[];
  name: string;
}

/**
 * useChangeLanguage
 * @return {Function}
 */
export default function useChangeLanguage(): any {
  const { i18n } = useTranslation();

  return async(value: string) => {
    // @ts-ignore - to resolve
    const lang: string = (mapI18N as Locale[])
      // @ts-ignore - to resolve
      .filter((l: Locale) => l.name === value)[0].keys[0];
    await i18n.changeLanguage(lang, (err: Error | null) => {
      if (err) {
        throw err;
      }
    });
  };
}

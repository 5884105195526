import { ReactNode } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { ValidateStatusType } from '@shopopop/react-hooks';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';

interface InputEmailProps {
  name: string;
  label: string;
  dataTestid: string;
  options?: {
    required?: boolean;
    disabled?: boolean;
    noEmailCheckbox?: boolean;
    validateStatus?: ValidateStatusType;
    help?: string;
  };
}

function InputEmail({
  name,
  label,
  dataTestid,
  options,
}: InputEmailProps): ReactNode {
  const { t } = useTranslation();
  const validateEmailAndNoAccents = useValidateEmailAndNoAccents();

  const formItemProps: {
    validateStatus?: ValidateStatusType;
    help?: string;
  } = {};

  if (options?.validateStatus) {
    formItemProps.validateStatus = options.validateStatus;
  }
  if (options?.help) {
    formItemProps.help = options.help;
  }

  return (
    <Form.Item
      name={name}
      label={label}
      {...formItemProps}
      // @ts-ignore - to resolve
      rules={options?.disabled ? [] : [
        {
          required: options?.required,
          message: t('EMAIL_REQUIRED'),
        },
        {
          validator: validateEmailAndNoAccents,
        },
      ]}
      style={options?.noEmailCheckbox ? {
        marginBottom: 5, // réduit l'espace entre le form item et la checbox si présente.
      } : {}}
    >
      {/*
        // @ts-ignore - to resolve */}
      <Input data-testid={dataTestid} disabled={options?.disabled} />
    </Form.Item>
  );
}

function useValidateEmailAndNoAccents() {
  const { t } = useTranslation();

  return function validateEmailAndNoAccents(_: RuleObject, value: StoreValue) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const noAccentsPattern = /^[^\u00C0-\u017F]*$/;

    if (!value) {
      return Promise.resolve();
    }

    if (!noAccentsPattern.test(value)) {
      return Promise.reject(new Error(t('ACCENT_NOT_ALLOWED')));
    }

    if (!emailPattern.test(value)) {
      return Promise.reject(new Error(t('WRONG_FORMAT_EMAIL')));
    }

    return Promise.resolve();
  };
}

export default InputEmail;
